import Seo from 'src/components/seo/Seo'
import RetireNaLoja from 'src/components/servicos/ServicesPage/RetireNaLoja/RetireNaLoja'

function Page() {
  return (
    <>
      <Seo language="pt-br" title="Retire na loja | Decathlon" />
      <RetireNaLoja />
    </>
  )
}

export default Page
